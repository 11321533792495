@import "/src/css/variables.scss";

.login2-actions {
    display: flex;
    flex-flow: column;
    gap: $u2;
}

.login2-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $u2;
    gap: $u2
}

.login2-app-handoff {
    a {
        padding: $u2;
        margin: auto;
    }
}
