
$dark-text: #131516;
$light-text: #fafbff;

$primary: #7ACE4C;
$secondary: #2a3238;
$background: #fafbff;
$danger-bg: #d82e42; //#d33d3d;
$danger-fg: #d82e42;
$warning-bg: #ff8f00;
$logo-font: "M PLUS Rounded 1c", "Roboto", Arial, sans-serif;
$heading-font: "Roboto", Geneva, Arial, sans-serif;
$body-font: "Carlito", Arial, sans-serif;
$monospace-font: "Roboto Mono", Courier, monospace;

$breakpoint-tablet: 767px;
$breakpoint-mobile: 479px;

$u1: 8px;
$u2: 16px;
