@import "/src/css/variables.scss";

.text {
    margin: 0;
}

.text-title {
    font-size: 32px;
    font-weight: 400;
}

.text-info {

}

.text-error {
    color: $danger-fg;
}
