
nav {
  display: flex;
  position: fixed;
  left: 0;
  width: 120px;
  height: auto;
  max-width: 120px;
  min-height: 100vh;
  z-index: 2;
  padding: 32px 0 32px;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-right: 1px solid #eee;
  box-shadow: 3px 3px 4px 2px rgba(0, 0, 0, 0.05);
}

.logo-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.logo {
  width: 60px;
}

.menu-items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  gap: 24px;
}

.menu-nav-items {
  flex-grow: 1;
  gap: 16px;
}

.menu-item {
  display: block;
  padding: 20px 0;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #fbfbfb;
  }
  &:active {
    background-color: #f0f0f0;
    box-shadow: inset 3px 3px 7px 4px rgba(0, 0, 0, 0.05);
  }
  &.current {
    background-color: #f7f7f7;
    box-shadow: inset 3px 3px 7px 4px rgba(0, 0, 0, 0.05);
  }

  .nav-icon {
    font-size: 28px;
    width: 36px;
    margin: 0 auto;
  }

  .nav-text {
    font-size: 16px;
    padding-top: 10px;
  }
}


.menu-logout-wrapper {
  flex-shrink: 0;
}



@media screen and (max-width: 767px) {

  nav {
    position: static;
    flex-direction: column;
    min-height: 0;
    width: 100%;
    max-width: 100%;
    padding: 0;
    background-color: none;
    box-shadow: none;
  }

  .menu-items {
    position: fixed;
    display: none;
    left: -75%;
    top: 0;
    height: 100%;
    width: 70%;
    padding-top: 48px;
    background-color: #fff;
    box-shadow: 3px 3px 4px 2px rgba(0, 0, 0, 0.05);
    z-index: 10;
  }

  .menu-item {
    justify-content: flex-start;
    padding: 16px;
  }

  .nav-icon {
    margin: 0;
  }

  .logo-title {
    font-family: $heading-font;
    font-size: 18px;
    font-weight: 400;
  }

  .top-bar {
    display: flex;
    width: 100%;
    height: 48px;
    align-self: flex-start;
    align-items: center;
    background-color: #fff;
    box-shadow: 3px 3px 4px 2px rgba(0, 0, 0, 0.05);
    &::after {
      flex: 1;
      content: "";
    }
  }

  .menu-button-wrapper {
    display: initial;
    flex: 1;
    padding-left: 16px;
    z-index: 11;
  }
  .menu-button {
    font-size: 24px;
  }
  
  .logo-block {
    flex: 1;
    margin-bottom: 0;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .logout-wrapper {
    display: none;
  }
}