.dass-question {
    margin: 30px 0;
    text-align: left;
}

.dass-options {
    min-width: 600px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    button {
        flex: 1;
        background-color: #fbfbfb;
        color: #333;
        border: 1px solid #ccc;

        &.selected {
            background-color: #7ace4c;
            color: #fff;
        }
    }
}