@import url('https://fonts.googleapis.com/css2?family=Carlito:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500&display=swap');
@import "variables";

* {
	box-sizing: border-box;
}
html {
	height: 100%;
}
body {
	margin: 0;
	min-height: 100%;
  height: 100%;
	background-color: #fff;
	font-family: $body-font;
	font-size: 16px;
	line-height: 20px;
	color: #333;
}

#root {
  height: 100%;
}

h1 {
  font-family: $heading-font;
  font-size: 55px;
  line-height: 44px;
  margin-top: 20px;
  margin-bottom: 8px;
}
// h2 {
//   font-size: 32px;
//   line-height: 36px;
//   margin-top: 20px;
// }
h2 {
  width: 100%;
  margin: 10px 0px 4px;
  font-family: $heading-font;
  color: #777;
  font-size: 32px;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  margin: 0px 0 8px;
}
h4 {
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
}
h5 {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}
h6 {
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
}
p {
  margin-top: 0;
  margin-bottom: 10px;
}

// ul {
//   padding: 0;
// }

hr {
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #ddd;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  &.link {
    text-decoration: underline;
  }
}

button, .w-button {
  display: inline-block;
  padding: 9px 15px;
  background-color: $primary;
  color: white;
  border: 0;
  margin: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: inherit;
  font-family: inherit;

  &:hover {
    background-color: lighten($primary, 4%);
  }
  &:focus {
    background-color: darken($primary, 4%);
  }
}

.danger-button, .danger-button[type="submit"] {
  background-color: $danger-bg;

  &:hover {
    background-color: lighten($danger-bg, 4%);
  }
  &:focus {
    background-color: darken($danger-bg, 4%);
  }
}

.secondary-button {
  background-color: $secondary;
  text-align: center;

  &:hover {
    background-color: lighten($secondary, 4%);
  }
  &:focus {
    background-color: darken($secondary, 4%);
  }
}

.clear-button {
  padding: 0;
  background-color: #00000000;
  color: $secondary;
  &:hover {
    background-color: #00000000;
  }
  &:focus {
    background-color: #00000000;
  }
}


.button-group {
	display: flex;
	button {
	  border-radius: 0;
	  margin: 0;
	  &:first-child {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	  }
	  &:last-child {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	  }
	}
  }
  
button[disabled], a[disabled], button[disabled]:hover, a[disabled]:hover {
  pointer-events: none;
  background-color: #eeeeee;
  color: #6e6e6e;
  box-shadow: none;
}
  

input[type="text"], input[type="email"], input[type="password"], input[type="number"], select, .input, textarea {
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0 8px;
  background-color: #fbfbfb;
  font-family: inherit;

  &:disabled {
    background-color: #eeeeee;
    color: #6e6e6e;
    pointer-events: none;
  }

  &:hover {
    border-color: #8af159;
  }
  
  &:focus {
    border-color: #8af159;
    outline: none;
  }
}


textarea {
  font-family: inherit;
  padding: 8px;
  height: auto;
}



form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.form-sections {
    gap: 32px;
  }

  .form-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    & > * {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
    & > *:not(.form-field-half) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  & .actions {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

.form-question label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}


.form {
  display: grid;
  width: 100%;
  justify-items: stretch;
  grid-auto-columns: 1fr;
  grid-row-gap: 16px;

  .tooltip {
    padding-top: 10px;
    padding-bottom: 0px;
    font-style: italic;
  }
  
  label {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  label.checkbox {
    display: inline;
    margin: 0;
    font-weight: normal;
  }

  .help-text {
    font-size: 13px;
    color: #777;
  }
}

.form-double-section {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}

.form-question.w-button {
  margin-bottom: 10px;
}

.form-field, .form-field-2 {
  display: flex;
  flex-direction: column;
  // gap: 2px;
}
.form-field-2 {
  grid-column-start: 1;
  grid-column-end: 3;
}




code {
  font-family: $monospace-font;
}

pre {
  font-family: inherit;
  margin: 0;
}

.rounded-container {
	width: 100%;
	padding: 24px;
	border-radius: 20px;
  border: 1px solid #eee;
	background-color: #fff;
	box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.05);
}

.danger-box {
  min-width: 400px;
  padding: 8px 16px;
  min-height: 0px;
  border-radius: 8px;
  color: #ffffff;
  background-color: $danger-bg;
}

.warning-box {
  min-width: 400px;
  padding: 8px 16px;
  min-height: 0px;
  border-radius: 8px;
  color: #ffffff;
  background-color: $warning-bg;
}

.danger-text {
  color: $danger-fg;
}

.warning-text {
  color: $warning-bg;
}

.success-text {
  color: $primary;
}

.errorlist { 
  color: $danger-fg;
  margin: 0;
}

.empty-message {
	color: #777;
	// font-size: 14px;
	margin: 8px 0;
}

.cursor-pointer {
  cursor: pointer;
}


.fa-wrapper {
	min-width: 19px;
}

.fa-button {
  cursor: pointer;
  font-size: 1.4em;
}
  
.fa-icon-button {
	display: inline-flex;
	align-items: center;
	gap: 12px;  
}


@media screen and (max-width: 479px) {

  .form-double-section {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .form-question.w-button {
    width: 100%;
  }
}