@import "base";
@import "layout/nav";
@import "layout/messages";
@import "layout/lists";
@import "modals";
@import "layout/pagination";

// :root {
//   --toastify-color-light: #7ace4c;
//   --toastify-text-color-light: red;
//   --toastify-color-success: #07bc0c;
// }

.Toastify__toast-theme--light.Toastify__toast--default {
  background-color: $primary;
  color: $light-text;
  border-radius: 8px;
}
.Toastify__close-button.Toastify__close-button--light {
  color: $light-text;
}


.container {
  position: relative;
  display: flex;
  height: auto;
  max-width: 100%;
  min-height: 100vh;
  padding-left: 120px;
}

main {
	width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
	padding: 16px 40px 20px;
  background-color: $background;
  flex-grow: 1;
  &.narrow {
    max-width: 960px;
    margin: 0 auto;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    flex-direction: column;
  }

  main {
    padding: 16px 0 0;
  }
}


.div-block {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: $background;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.rounded-container h2 {
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
  margin: 0 0 12px;
}

.heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    margin: 0;
  }
}

.heading-wrapper-actions {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
}


ul.block-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  li {
    list-style: none;
  }
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  // display: none;
  position: absolute;
  border-radius: 8px;
  background-color: $secondary;
  color: white;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  right: 0;
  margin-top: 8px;
  z-index: 10;
}

.dropdown-item {
  display: flex;
  background-color: $secondary;
  padding: 6px 8px;
  min-width: 200px;
  cursor: pointer;
  &:hover {
    background-color: lighten($secondary, 4%);
  }

  &.danger-button {
    background-color: $danger-bg;
    &:hover {
      background-color: lighten($danger-bg, 4%);
    }
  }
}



.tabs {
	width: 100%;
	display: flex;
	column-gap: 32px;
	flex-wrap: wrap;
	border-bottom: 1px solid #ccc;
	margin-bottom: 16px;
	padding-left: 16px;

	.tab {
		font-size: 20px;
		font-weight: bold;
		cursor: pointer;
		border-bottom: 3px solid rgba(0,0,0,0);
		padding: 0 16px 8px;
		// padding: 8px 16px;
		color: #aaa;
		&.active-tab {
			color: #333;
			border-bottom: 3px solid #7ace4c;
		}
    & .tab-badge {
      font-size: 12px;
      color: #fff;
      background-color: $danger-bg;
      padding: 2px 5px;
      border-radius: 20px;
      line-height: 0;
      position: relative;
      bottom: 10px;
      // margin-left: 2px;
    }
	}
}



.tooltip-content {
	background: #333;
	color: white;
	// padding: 4px 8px;
	border-radius: 8px;
	// display: none;
	// position: absolute;
	// width: max-content;
	max-width: 400px;
  z-index: 1000;
  font-size: 14px !important;
	line-height: 20px !important;
}

.tooltip-icon {
	color: #777;
	margin-left: 8px;
}

h2 .tooltip-icon, h3 .tooltip-icon {
	font-size: 0.8em;
}


.inline-selections {
  flex-wrap: wrap;
	display: inline-flex;
	gap: 8px;
	.inline-selection {
		display: inline-block;
		padding: 8px;
		background-color: #fff;
		border: 1px solid #777;
		border-radius: 8px;
		.remove-selection {
			cursor: pointer;
			margin-left: 6px;
			color: #777;
			&:hover {
				color: #333;
			}
		}
	}
}


@media screen and (max-width: 767px) {
  .container {
    flex-direction: column;
  }

  main {
    margin-left: 0px;
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  h1 {
    font-size: 38px;
    line-height: 38px;
    margin-top: 0;
  }

  h2 {
    font-size: 28px;
  }
  .heading-wrapper {
    display: initial;
  }

  .modal {
    width: 80%;
  }

  .modal-heading {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 479px) {
  .modal {
    width: 90%;
  }

  .modal-heading {
    text-align: center;
  }
}
