
.list-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  
  .list-item {
	display: grid;
	align-items: stretch;
	border-top: 1px solid #eee;
	& > * {
		padding: 8px 0;
		align-self: center;
	}
	&:hover {
	  background-color: #fbfbfb;
	}
	& > *:first-child {
	  padding-left: 16px;
	}
	& > *:last-child {
	  padding-right: 16px;
	}
  }

  *.tall-list .list-item {
	font-size: 16px;
  }

  &.tall-list .list-item > * {
	padding-top: 16px;
	padding-bottom: 16px;
  }

  .list-columns {
	color: #777;
	text-transform: uppercase;
	display: grid;
	font-weight: bold;
	& > *:first-child {
		padding-left: 16px;
	}
	& > *:last-child {
		padding-right: 16px;
	}
  }
}


.list-actions {
  display: flex;
  gap: 16px;
  width: auto;
  height: auto;
  flex-direction: row;
  align-items: center;
}

.list-action {
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  line-height: initial;
}

.list-action-text {
  font-size: 11px;
}

.list-action-icon {
  cursor: pointer;
  font-size: 1.4em;
  display: block;
  width: 24px;
  height: 24px;
}


@media screen and (max-width: 767px) {
	.list-action-icon {
	  font-size: 1em;
	}
}