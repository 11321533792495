@import "/src/css/base";

body {
	background-color: #fafbff;
}

.login-main {
	display: flex;
	height: 100vh;
	justify-content: center;
	background-color: #fafbff;


	.login-container {
		width: 480px;
		align-self: center;
		padding: 36px;
		margin: -8% 24px 0;
	}

	.signup-container {
		width: 800px;
		padding: 36px;
		margin-top: 40px;
		align-self: flex-start;
	}

	.logo-block {
		display: flex;
		flex-direction: row;
		gap: 16px;
		align-items: center;
		justify-content: center;
		margin-bottom: 36px;

		.logo {
			width: 40px;
			height: 29px;
		}

		.logo-title {
			font-family: $logo-font;
			font-size: 28px;
			font-weight: 400;
		}	
	}

	h4 {
		font-weight: normal;
		text-align: center;
	}

	.login-form {
		margin-top: 32px;
		display: flex;
		flex-direction: column;
		justify-items: stretch;
		gap: 20px;
	}


	@media screen and (max-width: 767px) {
		h1 {
		font-size: 38px;
		line-height: 38px;
		}
	
		h2 {
		font-size: 28px;
		}
	}

}