
.message-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 16px;
  bottom: 32px;
  width: 100%;
  z-index: 1;
}

.message {
  width: 400px;
  padding: 20px;
  margin: 0 auto;
  min-height: 0px;
  border-radius: 5px;
  color: #ffffff;
  box-shadow: 3px 3px 4px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 12px;
  .message-content {
    flex-grow: 1;
  }
}

.message span {
  float: right;
  font-size: 1.4em;
  align-self: center;
  cursor: pointer;
}

.success-message {
  background-color: $primary;
}

.error-message {
  background-color: $danger-bg;
}

.warning-message {
  background-color: $warning-bg;
}
