.comment { 
    max-width: 80%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border-radius: 8px;

    background-color: #eee;
    align-self: flex-start;

    &.own-comment {
        background-color: #cae6ff;
        align-self:  flex-end;
    }

    .comment-actions {
        visibility: visible;
        float: right;
        display: flex;
        gap: 8px;
        margin-left: 16px
    }
    .comment-actions.hidden {
        visibility: hidden;
    }

    &:hover .comment-actions {
        visibility: visible;
    }
}

.comment-sender {
    font-weight: 600;
    font-size: 16px;
}

.comment-timestamp {
    color: #777;
    // font-size: 13px;
    margin-left: 8px;
}

