
.modal-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  // display: none;
  padding-top: 100px;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(51, 51, 51, 0.3);
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: auto;
  max-width: 500px;
  margin: 0 auto;
  padding: 16px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 4px 2px rgba(0, 0, 0, 0.05);
}

.modal-heading {
  font-family: $heading-font;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
