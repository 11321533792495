.feedback-list-grid {
	grid-template-columns: 250px 250px 150px 350px 300px;
}
.my-patients-list-grid {
	grid-template-columns: 48px 1fr 340px 320px 120px;
}
.shared-patients-list-grid {
	grid-template-columns: 1fr 1fr;
}
.doctors-list-grid {
	grid-template-columns: 300px 1fr 92px;
}
.operators-list-grid {
	grid-template-columns: 1fr 120px;
}
.deleted-list-grid {
	grid-template-columns: 180px 1fr 180px 200px 220px 92px;
}
.patient-flags {
	display: inline-flex;
	gap: 4px;
}

.list-column {
	cursor: pointer;
}

.search-wrapper {
	position: relative;
	gap: 8px;
	img {
		position: absolute;
		height: 20px;
		margin: 10px 0;
		left: 16px;
		opacity: 0.46;
	}
	input {
		padding-left: 46px;
	}
}

.flag {
	border-radius: 20px;
	padding: 2px 12px;
	font-size: 13px;
	color: #fff;
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-self: baseline;
	img {
		height: 12px;
		margin-right: 8px;
	}
}

@media screen and (max-width: 767px) {
	.my-patients-list-grid {
		grid-template-columns: 1fr 1fr 1fr 88px;
	}
}