@import "../variables";

button.pagination {
    color: $dark-text;
    padding: 8px 12px;
    background-color: #fbfbfb;
    border: 1px solid #ccc;
    &:hover {
        background-color: lighten(#fbfbfb, 4%);
    }
    &:focus {
        background-color: darken(#fbfbfb, 4%);
    }
    &.active {
        background-color: $primary;
        color: $light-text;
        &:hover {
            background-color: $primary;
        }
        &:focus {
            background-color: $primary;
        }
    }
}