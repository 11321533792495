
.warning-border {
	border: 3px solid #666;
}

.dass21-list-grid {
	grid-template-columns: 1.5fr 1fr 1fr 1fr;
}

.treatments-list-grid {
	grid-template-columns: 2fr 1fr 1fr 1fr 120px;
}
.medications-list-grid {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 100px;
}
.reviews-list-grid {
	grid-template-columns: 160px 320px 1fr 80px;
	column-gap: 8px;
}
.files-list-grid {
	grid-template-columns: 1fr 1fr 1fr 80px;
}
.tms-course-list-container {
	display: flex;
}
.tms-actions-background {
	width: 102px;
	border-left: 1px solid #eee;
	margin-top: 16px;
	position: relative;
	z-index: 8;
	// &::before {
	// 	content: " ";
	// 	height: 100%;
	// 	position: absolute;
	// 	top: 0;
	// 	width: 15px;
	// 	box-shadow: -15px 0 15px -15px inset rgba(0, 0, 0, 0.4);
	// 	left: -15px;
	// }
}
.tms-list-grid {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 90px;
}

@keyframes newly-updated {
	0% { background-color: initial; }
	1% { background-color: #fdeeb0; }
	100% { background-color: initial; }
}

.tms-full-list-grid {
	grid-template-columns: 160px 140px 140px 160px 180px 190px 190px 210px 180px 190px 190px 220px 260px 0px;
	padding-right: 16px;
	gap: 16px;
	width: min-content;

	&.newly-updated {
		animation-name: newly-updated;
		animation-duration: 3s;
		animation-delay: 0s;
	}

	input.number {
		width: 60px;
		margin-right: 8px;
		text-align: center;
	}
	& > * {
		height: 100%;
	}
	.course-actions {
		justify-content: space-around;
		position: absolute;
		right: 65px; // 40px padding + 1px border + 24px padding
		width: 96px;
		padding-left: 16px;
		align-self: flex-start;
		height: initial;
		z-index: 9;
	}
	.course-actions:not(.list-column) {
		border-top: 1px solid #eee;
		margin-top: -1px;
	}
}
.list-container {
	overflow-x: scroll;
}

.account-setup-tasks {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 0;
	li {
		list-style: none;
		font-size: 17px;
		.fa-circle-check {
			color: #7ace4c;
		}
		.fa-circle-o {
			color: #777;
		}
	}
}

.good-news-container {
	border: 4px solid #7ace4c;
	padding: 0;
	font-size: 16px;
	h3 {
		color: #fff;
		background-color: #7ace4c;
		margin: 0;
		padding: 8px;
		font-style: italic;
	}
}

.overview-container {
	display: grid;
	grid-template-columns: 1fr 1.1fr;
	& > div {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
}

.flex-col-gap-32 {
	display: flex;
	flex-direction: column;
	row-gap: 32px;
}


.flex-col-gap-24 {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
}

.flex-col-gap-16 {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
}

.flex-col-gap-8 {
	display: flex;
	flex-direction: column;
	row-gap: 8px;
}

.flex-row-gap-8 {
	display: flex;
	flex-direction: row;
	column-gap: 8px;
	align-items: center;
}

.grid-2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 24px;
}

#graphics {
	display: grid;
	grid-auto-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	text-align: center;
	margin-top: 12px;
	svg:not(.tooltip-icon) {
		width: 140px;
		height: 98px;
		margin: 0 auto;
	}
}

#graphicsDass {
	display: grid;
	grid-auto-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	text-align: center;
	margin-top: 12px;
	svg {
		width: 120px;
		height: 84px;
		margin: 0 auto;
	}
}


.onboarding-section {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: 16px 0;
}

.overview-comment {
	border-top: 1.5px solid #ccc;
	padding-top: 12px;
	grid-column-start: 1;
	grid-column-end: 4;
}

// .latest-physiological {
// 	border-right: 1.5px solid #ccc;
// }

.span-2 {
	grid-column-start: 1;
	grid-column-end: 3;
}

#patientDetails {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.inner-rect {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 8px;
	row-gap: 8px;
	border: 1.5px solid #ccc;
	border-radius: 8px;
	grid-column-start: 1;
	grid-column-end: 3;
}
.inner-rect-heading {
	grid-column-start: 1;
	grid-column-end: 4;
	font-size: 18px;

	a {
		font-size: 14px;
		margin-left: 8px;
		vertical-align: bottom;
	}
}

.overview-physiological {
	border-right: 1px solid #ddd;
	padding-right: 16px;
}
.overview-dass {
	padding-left: 16px;
}

.course-actions {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

@media screen and (max-width: 767px) {

	.grid-2 {
		grid-template-columns: 1fr;
		row-gap: 16px;
	}

	.overview-container {
		display: grid;
		grid-template-columns: 1fr;
		gap: 24px;
	}

	.overview-physiological, .overview-dass {
		border: none;
		padding-right: 0;
		padding-left: 0;
	}

	.round-rect-container {
		grid-template-columns: 1fr;
	}
	#patientDetails {
		grid-template-columns: 1fr 1fr;
	}
	.span-2 {
		grid-column-start: 1;
		grid-column-end: 2;
	}
}